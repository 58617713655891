<template>
  <section class="service" id="service" data-view>
    <Header></Header>
    <scrollAnalytics></scrollAnalytics>

    <div class="service__content">
      <div class="service__top">
        <div class="service__top--left">
          <div class="service__top--details">{{ $t('services.list.clm.details') }}</div>
          <h2 class="section__title service__title iosTitle type1">

            <span class="text-wrapper" v-for="(key, value) in $t('clm.title')" :key="value">
              <span class="letters" v-html="key"></span>
            </span>

          </h2>
        </div>
        <div class="service__top--right">
          <router-link class="service__link section__item--link cursor-hover" to="/services/medical-web">
            <span class="underline">
              {{ $t('services.list.medicalWeb.name') }}
            </span>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                    fill="#0BE9C9"/>
            </svg>
          </router-link>
          <router-link class="service__link section__item--link cursor-hover" to="/services/medical-vr">
            <span class="underline">
              {{ $t('services.list.medicalVR.name') }}
            </span>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                    fill="#0BE9C9"/>
            </svg>
          </router-link>
          <router-link class="service__link section__item--link cursor-hover" to="/services/medical-explainer">
            <span class="underline">
              {{ $t('services.list.medicalExplainer.name') }}
            </span>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                    fill="#0BE9C9"/>
            </svg>
          </router-link>
        </div>
      </div>

      <div class="service__video-wrapper">
        <div class="service__play-btn cursor-hover" @click="playVideo" v-bind:class="[{ active:  !videoTrigger}]"></div>
        <video @click="playVideo" ref="video" class="service__video" poster="../../assets/img/services/clm/clm-poster.jpg">
          <source type="video/mp4" src="@/assets/video/services/INCH_Showreel_2022_CLM-E-Detailing__009.mp4">
        </video>
      </div>

      <div class="service__description">
        <p class="service__description--title">{{ $t('clm.description') }}</p>
        <div>
          <div class="service__subtitle">{{ $t('clm.subtitle') }}</div>
          <p class="service__text">{{ $t('clm.text[0]') }}</p><br>
          <p class="service__text">{{ $t('clm.text[1]') }}</p><br>
          <p class="service__text">{{ $t('clm.text[2]') }}</p>
        </div>
      </div>
      <ul class="service__partners--list">
        <li class="service__partners--item">
          <img src="@/assets/img/services/clm/email.png">
        </li>
        <li class="service__partners--item">
          <img src="@/assets/img/services/clm/clm.png">
        </li>
        <li class="service__partners--item">
          <img src="@/assets/img/services/clm/engage.png">
        </li>
        <li class="service__partners--item">
          <img src="@/assets/img/services/clm/crm.png">
        </li>
        <li class="service__partners--item">
          <img src="@/assets/img/services/clm/detailing.png">
        </li>
        <li class="service__partners--item">
          <img src="@/assets/img/services/clm/personal.png">
        </li>
      </ul>
      <router-link class="service__link--main section__item--link cursor-hover" to="/contact">
        <span class="underline">
          {{ $t('clm.mainLink') }}
        </span>
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.3413 1.14797C10.1444 0.951031 9.82782 0.948294 9.63425 1.14186C9.44068 1.33543 9.44341 1.652 9.64035 1.84894L17.2791 9.48786L1.08724 9.52401C0.811161 9.52463 0.592312 9.74898 0.598423 10.0251C0.604534 10.3013 0.83329 10.5246 1.10936 10.524L17.2631 10.4879L9.62673 18.1252C9.43317 18.3188 9.43592 18.6354 9.63287 18.8323C9.82983 19.0292 10.1464 19.0319 10.34 18.8384L18.8348 10.3425L19.1852 9.99205L18.8286 9.63548L10.3413 1.14797Z"
                fill="#0BE9C9"/>
        </svg>
      </router-link>
      <div class="service__cards-wrapper">
        <similar :dataSlider="similarData"></similar>
      </div>
    </div>
    <Footer></Footer>
  </section>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/NewFooter.vue';
import anime from 'animejs';
import {mapMutations} from 'vuex';
import similar from '@/components/similar.vue';

export default {
  name: 'CLM',
  components: {
    Header,
    Footer,
    similar,
  },
  data() {
    return {
      videoTrigger: false,
      similarData: {
        title: 'similar-title-type2',
        prevLink: "",
        nextLink: "",
        linkShow: false,
        slider: [
          'medicalVR',
          'medicalExplainer',
          'medicalWeb',
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.setCurrentPage('services');

      const whySection = document.querySelector('#service');
      const textWrapperAll = whySection.querySelectorAll('.text-wrapper .letters');
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes('®')) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter2'>$&</span>").replace("®", "<sup>®</sup>");

        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter2'>$&</span>");
        }

        anime.timeline({loop: false})
          .add({
            targets: '.text-wrapper .letter2',
            translateY: ['1.1em', 0],
            translateZ: 0,
            duration: 750,
            delay: (el, i) => 50 * i,
          });
      });
    }, 40)
  },
  methods: {
    ...mapMutations([
      'setCurrentPage',
    ]),
    playVideo() {
      const mediaVideo = this.$refs.video;
      if (mediaVideo.paused) {

        gtag('event', 'scroll', {'event_category': 'play video', 'event_label': this.$route.path})

        mediaVideo.play();
        this.videoTrigger = true;
      } else {
        mediaVideo.pause();
        this.videoTrigger = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  &__title {
    text-align: left;
    font-family: "TimesNewRoman-I";
    font-style: italic;
    color: var(--footer-text-color);
    font-weight: 400;
    font-size: 80px;
    line-height: 0.7em;
    position: relative;
    margin-bottom: 0;
    margin-top: 32px;

    @media screen and (max-width: 1024px) {
      font-size: 66px;
      margin-top: 20px;
    }

    @media screen and (max-width: 590px) {
      font-size: 44px;
      margin-top: 16px;
    }


    ::v-deep .letter2 {
      display: inline-block;
      line-height: 1em;
    }

    .text-wrapper {
      position: relative;
      display: inline-block;
      overflow: hidden;
      padding: 0.1em 0.15em .1em 0;

      &:first-child {
        padding-right: 0;
      }
    }
  }

  &__item {
    text-align: left;

    &--text {
      margin-top: 12px;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 8px;
      color: var(--text-secondary-color);
    }

    &--link {
      font-family: "TimesNewRoman-I";
      letter-spacing: 0.04em;
      color: var(--text-primary-color-case);
      font-style: italic;
      position: relative;
      text-decoration: none;

      text-align: right;

      //display: flex;
      //align-items: flex-end;

      @media screen and (max-width: 660px) {
        display: inline-block;
        text-align: left;
      }

      .underline {
        width: 100%;
        background-image: linear-gradient(transparent calc(100% - 1px), #29F6D9 1px);
        background-repeat: no-repeat;
        background-size: 99% 100%;
        transition: background-size 1s;
      }

      svg {
        transform: rotate(-45deg);
        transition: transform .2s ease;
      }

      &.second {
        .underline {
          background-size: 98% 100%;
        }
      }

      @media screen and (min-width: 1023px) {
        &:hover {
          svg {
            transform: rotate(0deg)
          }

          .underline {
            background-size: 0% 100%;
          }
        }
      }

      &:active {
        svg {
          transform: rotate(0deg)
        }

        .underline {
          background-size: 0% 100%;
        }
      }
    }
  }
}

.service {
  &__title {
    .text-wrapper:first-child {
      padding-right: 0.15em;
    }

    .text-wrapper:nth-of-type(2) {
      padding-left: 0.04em;
      padding-right: 0.1em;
    }
  }

  &__play-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;

    @media screen and (min-width: 1023px) {
      &:hover {
        &:before {
          transform: scale(1.1);
        }
      }
    }

    &.active {
      display: block;

      &:before {
        opacity: 1;
      }
    }

    &:before {
      content: 'Play';
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      opacity: 0;

      justify-content: center;
      align-items: center;
      background: #29F6D9;

      z-index: 2;

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      margin: auto;

      color: #000000;

      transition: all .2s ease;

      font-family: "TimesNewRoman-I";
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.04em;

      @media screen and (max-width: 425px) {
        width: 60px;
        height: 60px;
        font-size: 16px;
      }
    }
  }

  &__top {
    padding: 178px 45px 0 45px;

    max-width: calc(1125px + 90px);
    width: 100%;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: left;

    @media screen and (max-width: 660px) {
      align-items: flex-start;
    }

    &--details {
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #92A1AE;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding-left: 5px;
    }

    &--right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: 15px;
      margin-bottom: .8%;

      max-width: 240px;
      width: 100%;


      @media screen and (max-width: 1024px) {
        margin-bottom: -.4%;
      }

      @media screen and (max-width: 660px) {
        margin-bottom: 0;
        margin-left: 0;
        max-width: 100%;
      }
    }
  }

  &__link {
    margin-bottom: 16px;
    letter-spacing: 0.04em;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-family: 'Montserrat';

    &--main {
      color: var(--menu-icon-color);
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__video-wrapper {
    margin-top: 64px;
    position: relative;

    video {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: var(--footer-text-color);
    margin-bottom: 16px;
  }

  &__description {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 128px auto 68px auto;

    padding: 0 45px;

    text-align: left;
    display: grid;
    grid-template-columns: 1fr 2fr;

    &--title {
      color: var(--footer-text-color);
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      line-height: 2.8em;
      letter-spacing: 0.08em;

      @media screen and (max-width: 768px) {
        line-height: 2.5em;
      }
    }
  }

  &__partners {
    &--list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 0 45px;

      max-width: calc(1108px + 90px);
      width: 100%;

      margin: 0 auto 80px auto;
    }

    &--item {
      border: 2px solid var(--service-border-color);
      border-left: none;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background-item-color-hover);
      padding: 0 27px;

      &:nth-of-type(1) {
        border-left: 2px solid var(--service-border-color);
      }

      &:nth-of-type(4) {
        border-left: 2px solid var(--service-border-color);
        border-top: none;
      }

      &:nth-of-type(5) {
        border-top: none;
      }

      &:nth-of-type(6) {
        border-top: none;
      }

      img {
        height: 39px;
        width: auto;
        display: block;
        object-fit: contain;
      }
    }
  }

  &__secondary-title {
    font-family: "TimesNewRoman-I";
    font-style: italic;
    font-weight: 400;
    font-size: 80px;
    line-height: 80px;
    text-align: left;
    display: flex;
    align-items: center;

    svg {
      margin-left: 16px;

      path {
        stroke: var(--footer-text-color);
      }

      circle {
        stroke: var(--footer-text-color);
      }
    }
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }

}


@media screen and (max-width: 1200px) {
  .service {

    &__partners {
      &--item {
        height: 108px;
        padding: 0 16px;
      }
    }

    &__secondary-title {
      font-size: 60px;
      line-height: 60px;

      svg {
        max-width: 80px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .service {

    &__partners {
      &--item {
        height: 110px;
        padding: 0 16px;

        img {
          height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .service {

    &__video-wrapper {
      margin-top: 40px;
    }

    &__top {
      padding-top: 104px;

      &--details {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 16px;
      }
    }

    &__partners {
      &--item {
        height: 110px;
        padding: 0 16px;
      }
    }

    &__description {
      margin: 48px 0;
    }

    &__subtitle {
      font-size: 24px;
    }

    &__partners {
      &--list {
        margin-bottom: 48px;
      }
    }

    &__secondary-title {
      font-size: 44px;
      line-height: 44px;

      svg {
        max-width: 100px;
      }
    }
  }
}

@media screen and (max-width: 660px) {

  .section {

    &__item--link svg {
      max-width: 13px;
      margin-bottom: -5px;
    }
  }
  .service {
    &__video-wrapper {
      margin-top: 32px;
    }

    &__top {
      display: flex;
      flex-direction: column-reverse;
      //padding-left: 20px;
      //padding-right: 20px;

      &--right {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        align-items: start;
        margin-bottom: 24px;
      }
    }

    &__description {
      grid-template-columns: 1fr;
      margin-top: 40px;
      margin-bottom: 36px;
      //padding-right: 20px;
      //padding-left: 20px;

      &--title {
        margin-bottom: 26px;
      }
    }

    &__subtitle {
      font-size: 20px;
      line-height: 28px;
    }

    &__partners {
      &--list {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        margin-bottom: 32px;
      }

      &--item {
        height: 75px;

        &:nth-of-type(2) {
          padding: 0 40px;
        }

        &:nth-of-type(3) {
          padding: 0 30px;
          grid-column: 1;
          grid-row: 2;
          border-left: 2px solid var(--service-border-color);
          border-top: 0;
          border-bottom: 0;
        }

        &:nth-of-type(4) {
          grid-column: 1;
          grid-row: 3;
          border-top: 2px solid var(--service-border-color);
        }

        &:nth-of-type(5) {
          grid-column: 2;
          grid-row: 2;
          border-bottom: 0;
        }

        &:nth-of-type(6) {
          grid-column: 2;
          grid-row: 3;
          border-top: 2px solid var(--service-border-color);

        }

        img {
          height: 24px;
        }
      }
    }

    &__secondary-title {
      svg {
        max-width: 64px;
      }
    }

    &__link {
      &--main {
        text-align: left;
        padding-left: 45px;
        padding-right: 45px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    // &__text ~ br {
    //   display: none;
    // }
  }
}

@media screen and (max-width: 425px) {
  .service {


    &__top {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__description {
      padding-right: 20px;
      padding-left: 20px;
    }

    &__partners {
      &--list {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__link {
      &--main {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .service {
    &__top {
      &--right {
        grid-template-columns: 58px 74px 1fr;
      }
    }
  }
}

</style>
